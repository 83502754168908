@import "../../sass/color.scss";
@import "../../sass/font.scss";

.page {
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
}

.blocked {
  text-align: justify;
  text-align-last: none;
}

section {
  align-items: center;
  display: grid;
  padding: 100px;
  min-height: 600px;

  .contentArea {
    max-width: 1500px;
    justify-self: center;
    display: grid;
    gap: 50px;
    word-wrap: break-word;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(2, 1fr);

    .content {
      word-wrap: break-word;
      padding: 25px;
    }

    .header {
      color: inherit;
    }

    .sub {
      color: inherit;
      opacity: 80%;
    }

    .topic {
      color: inherit;
      opacity: 80%;
    }
  }

  .title {
    text-align: center;
  }

  .content {
    display: flex;
    max-width: 750px;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    gap: 40px;
    white-space: pre-wrap;

    .item {
      display: flex;
      flex-direction: column;
    }
  }

  button {
    color: black;
    padding: 1em;
    border: 0px;
    border-bottom: 1px solid transparent;
    text-transform: uppercase;
    background: var(--background);
    width: 100px;
    border-radius: 99px;
  }

  button:hover {
    cursor: pointer;
    border-bottom: 1px solid rgba(2, 24, 2, 0.2);
  }
}

section.home {
  grid-template-columns: 2fr;
  grid-template-rows: 1fr 0.25fr;
  padding: 250px 50px 0px 75px;

  .homeArea {
    justify-self: center;
    display: grid;
    gap: 50px;
  }

  img {
    grid-row: 1;
    grid-column: 1;
    justify-self: center;
    max-width: 400px;
    border-radius: 7.5px;
  }

  .content {
    display: flex;
    gap: 50px;
    grid-row: 1;
    grid-column: 2;

    .greetingWrapper {
      text-align: center;
      display: flex;
      flex-direction: column;
    }
  }

  .socialLinks {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;

    svg {
      min-height: 24px;
      height: 24px;
      width: 24px;
    }
  }

  .properties {
    text-align: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .arrowDown {
    position: relative;
    bottom: 25px;
    align-self: center;
    justify-self: center;
    color: black;

    svg {
      height: 25px;
      width: 25px;
      color: black;
      cursor: pointer;

      :hover {
        transform: scale(1.1);
        transition: all 0.2s ease-in-out;
      }
    }

    img {
      height: 48px;
      width: 48px;
    }
  }
}

section.light {
  background-color: white;

  .title {
    color: black;
    text-align: center;
    align-self: center;
    grid-column: 2;
  }

  .content {
    justify-self: center;
    grid-column: 1;
    grid-row: 1;
    color: black;
  }
}

section.dark {
  background-color: black;

  .title {
    grid-column: 1;
    color: white;
    text-align: center;
    align-self: center;
  }

  .content {
    justify-self: flex-start;
    grid-column: 2;
    color: white;
  }
}

section.contact {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;
  background-color: #efefef;

  .contentArea {
    display: flex;
    flex-direction: column;

    .title {
      padding: 20px;
    }

    .content {
      align-self: center;
      text-align: center;
      gap: 20px;
    }
  }
}

.title {
  font-family: "Inter-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 54px;
  text-transform: uppercase;
}

.titleLarge {
  font-family: "Inter-Bold";
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 24px;
}

.titleMedium {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
}

.header {
  font-family: "Inter-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.sub {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.topic {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
}

//smaller phone viewpoints
@media (max-width: 320px) {
  section.home {
    padding: 10px;
    gap: 0px;

    img {
      justify-self: center;
      width: 75%;
    }

    .arrowDown {
      display: none;
    }
  }

  section {
    padding: 0px;

    .contentArea {
      word-break: break-all;
      grid-template-rows: 0.25fr 2fr;
      grid-template-columns: 1fr;
    }
  }

  section.light {
    .title {
      grid-row: 1;
      grid-column: 1;
    }

    .content {
      grid-row: 2;
      // grid-column: 1;
    }
  }

  section.dark {
    .title {
      grid-row: 1;
      grid-column: 1;
    }

    .content {
      justify-self: flex-start;
      grid-column: 1;
      grid-row: 2;
    }
  }

  section.home {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 0.25fr;
    padding-top: 100px;
  }
}

@media screen and (max-width: 425px) {
  section.home {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 0.25fr;
    padding-top: 100px;

    img {
      justify-self: center;
      width: 75%;
      display: block;
    }

    .arrowDown {
      display: none;
    }
  }

  section {
    padding: 35px;

    .contentArea {
      word-break: break-word;
      grid-template-rows: 1fr 2fr;
      grid-template-columns: 1fr;
    }
  }

  section.light {
    .title {
      grid-row: 1;
      grid-column: 1;
    }

    .content {
      grid-row: 2;
    }
  }

  section.dark {
    .title {
      grid-row: 1;
      grid-column: 1;
    }

    .content {
      justify-self: flex-start;
      grid-column: 1;
      grid-row: 2;
    }
  }
}

@media screen and (max-width: 800px) {
  section.home {
    padding: 0px;

    .content {
      grid-row: 2;
      grid-column: 1;

      .greetingWrapper {
        text-align: center;
        display: flex;
        flex-direction: column;
      }
    }

    img {
      grid-row: 1;
      grid-column: 1;
      justify-self: center;
      width: 75%;
      display: block;
    }

    .arrowDown {
      display: none;
    }
  }

  section {
    padding: 35px;

    .contentArea {
      word-break: break-word;
      grid-template-rows: 1fr 4fr;
      grid-template-columns: 1fr;
    }
  }

  section.light {
    .title {
      grid-row: 1;
      grid-column: 1;
    }

    .content {
      grid-row: 2;
    }
  }

  section.dark {
    .title {
      grid-row: 1;
      grid-column: 1;
    }

    .content {
      justify-self: flex-start;
      grid-column: 1;
      grid-row: 2;
    }
  }

  section.home {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 0.25fr;
    padding-top: 100px;
  }
}