.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.iconRotate {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.3s ease;
  transform: rotate(180deg);
}
.headline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header {
  font-family: "Inter-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.sub {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.sub::after {
  content: " ";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #efefef;
  margin-top: 10px;
}

.sub::before {
  content: " ";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #efefef;
  margin-bottom: 10px;
}
