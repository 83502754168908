body {
  margin: 0;
  font-family: "Circular Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}

*, *::before, *::after {
  box-sizing: border-box;
}

code {
  font-family: "Circular Pro", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

@font-face {
  font-family: "Inter-Regular";
  src: url("assets/font/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Bold";
  src: url("assets/font/Inter-Bold.ttf") format("truetype");
}
