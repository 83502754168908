@import "./sass/color.scss";

:root {
  --background: #f5f5f5;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.appContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;

  main {
    // padding-top: 100px;
  }
}
