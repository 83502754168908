.logo {
  background: var(--background);
  text-transform: uppercase;
  padding-left: 25px;
}

header {
  background: var(--background);
  text-align: center;
  position: fixed;
  z-index: 999;
  width: 100%;
}

nav {
  display: none;
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background: var(--background);
  width: 100%;
  transform: scale(1, 0);
  transition: transform 0.3s ease-in-out;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin-bottom: 1em;
    margin-left: 1em;

    button {
      cursor: pointer;
      color: black;
      padding: 1em;
      border: 0px;
      border-bottom: 1px solid transparent;
      text-transform: uppercase;
      background: var(--background);
    }

    button:hover {
      border-bottom: 1px solid rgba(2, 24, 2, 0.2);
    }
  }
}

.visible {
  display: block;
  transform: scale(1, 1);
}

.navToggle {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 1em;
  height: 100%;
  border: none;
  background-color: var(--background);
}

.navToggle span,
.navToggle span::after,
.navToggle span::before {
  display: block;
  background: black;
  height: 2px;
  width: 2em;
  border-radius: 2px;
  position: relative;
}

.navToggle span::after,
.navToggle span::before {
  content: "";
  position: absolute;
}

.navToggle span::before {
  bottom: 7px;
}

.navToggle span::after {
  top: 7px;
}

@media screen and (min-width: 800px) {
  .navToggle {
    display: none;
  }

  header {
    display: grid;
    grid-template-columns: 0.25fr auto minmax(700px, 1fr) 0.25fr;
  }

  .logo {
    z-index: 1;
    grid-column: 1;
  }

  nav {
    all: unset;
    grid-column: 3 / span 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  nav ul {
    display: flex;
    justify-content: flex-end;
  }
  nav li {
    margin-left: 1em;
    margin-bottom: 0;
  }
}
