.wrapper {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 60px; /* Height of the footer */
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
  justify-content: center;

  color: white;
  background-color: #212121;

  a {
    color: white;
    text-decoration: none;
  }
}
