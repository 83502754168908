.page {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  gap: 25px;
  padding: 200px 0px;
}

@media (max-width: 320px) {
  .content {
    max-width: 300px;
  }
}

@media screen and (max-width: 425px) {
  .content {
    max-width: 375px;
  }
}

@media screen and (max-width: 800px) {
  .content {
    padding: 20px;
    max-width: 750px;
  }
}
